
import axios from 'axios'
axios.defaults.withCredentials = true;
// axios.defaults.baseURL = 'https://vilfer.evotic.es/';
axios.defaults.baseURL = 'https://gestion.servipro24h.com/';
// axios.defaults.baseURL = 'http://localhost:8000/';
axios.defaults.timeout = 5000;

export default {
  namespaced: true,
  state: {
    user :  null,
    is_auth : false,
    is_technician : false
  },
  getters: {
    currentUser : state =>  {
      return !!state.is_auth;
    },
    currentTechnician : state =>  {
      return !!state.is_technician;
    },
  },
  mutations: {
    setUser(state, user){
      state.user = user;
      state.is_auth = Boolean(user);
      state.is_technician = Boolean(user.technician);
    },
  },
  actions: {
    async login( { commit }, credentials ) {
        await axios.get('/sanctum/csrf-cookie');
        await axios.post("/api/auth/login", credentials ).then(res => {
        commit('setUser',res.data.user)
      }).catch(() =>{});
    },
    async logout( { commit }) {
        await axios.post("/api/auth/logout").then(res => {
          commit('setUser',null)
        }).catch(() =>{});
    },
    async getUser({ commit } ) {
      await axios.get('/api/auth/user').then( res => {
        commit('setUser',res.data)
      })
      .catch(() => {})
    }
  },
}
